import React from "react";
import SmartForm from "../SmartForm"
import SocialShare from "../SocialShare"
import { useTranslation } from "react-i18next";
import rehypeReact from 'rehype-react'
import MarkdownImage from '../MarkdownImage'
import './about.scss'

export default ({ data, content }) => {

    const { t } = useTranslation()
    const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: { 'markdown-image': MarkdownImage },
    }).Compiler

    return(
        <div id="about" class="blog-area blog-no-sidebar pt-100 pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 blog-post-item ml-auto mr-auto">
                        <div class="blog-wrapper blog-details">
                            <div class="blog-content">
                                { renderAst(content) }
                            </div>
                        </div>
                        <div className="question-area">
                        <h3>{t('can_we_help_you')}</h3>
                            <SmartForm 
                                successTitle={t('your_message_has_been_sent_successfully')}
                                successMessage={t('our_colleagues_will_contact_you_as_soon_as_possible')} 
                                formClassName="about-form" 
                                formName="contact-submissions" 
                                mailSubject="Tiliana kapcsolatfelvétel"
                                stateProps={["name", "email", "phone", "message"]}>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="single-input">
                                            <label>{t('my_name')}</label>
                                            <input name="name" type="text" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-md-6">
                                        <div class="single-input">
                                            <label>{t('my_email')}</label>
                                            <input name="email" type="email" required />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="single-input">
                                            <label>{t('my_phone')}</label>
                                            <input name="phone" type="phone" required />
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="single-input">
                                            <label>{t('offer_request')}</label>
                                            <textarea className="form-control" name="message" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div class="single-input">
                                            <button className="sent-btn" type="submit">
                                                {t('send_message')}
                                            </button>
                                        </div>
                                    </div>                                   
                                </div>
                            </SmartForm>
                        </div>
                        
                        <div class="common-tag-and-next-prev mt-60">
                            <SocialShare title={data.title} hashtags="about,us"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}